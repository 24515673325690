<template>
    <mega-card class="rounded h-100 mb-0">

        <div class="navbar bg-white border-bottom rounded-top">

            <div class="container-fluid py-3">

                <router-link :to="'/user/'+data.idt_user" class="w-100 navbar-label mr-auto text-dark">

                    <mega-image class="rounded-full navbar-avatar mr-3" small :src="data.avatar"/>

                    <div class="content" style="width: calc(100% - 78px);">
                        <small class="strong text-overflow">{{data.name}}</small>
                        <small class="text-muted text-overflow">

                            <span :title="data['sessions'][0] ? $u2d(data['sessions'][0]['a_time'], 'DD.MM.YYYY - LT') : ''"
                                  v-if="device"
                                  :class="{'text-success': device === 'i-android'}"> <i :class="device"></i> - </span>

                            {{data.username}}
                        </small>
                    </div>
                </router-link>
            </div>
        </div>

        <nav class="nav flex-column font-small w-100">

            <div class="nav-text text-muted">
                <i class="i-mail"></i>
                <span>{{data.email || '- - -'}}</span>
            </div>

            <div class="nav-text text-muted">
                <i class="i-gift"></i>
                <span>{{data['birthday_date'] || '- - -'}}</span>
            </div>

            <div class="nav-text text-muted">
                <i class="i-user-plus"></i>
                <span>{{$u2d(data['a_time'], 'DD.MM - LT') || '- - -'}}</span>
            </div>
        </nav>
    </mega-card>
</template>

<script>
  export default {
    props: {
      data: Object
    },
    data() {
      return {}
    },
    computed: {
      device() {
        let d = undefined;

        if(!this.data['sessions'][0])
          return d

        let s = this.data['sessions'][0]['user_agent'].toLowerCase();

        d = 'i-monitor';
        if(s.includes('ios')) d = 'i-apple';
        if(s.includes('android')) d = 'i-android';

        return d;
      }
    },
    methods: {}
  }
</script>
