var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mega-card", { staticClass: "rounded h-100 mb-0" }, [
    _c("div", { staticClass: "navbar bg-white border-bottom rounded-top" }, [
      _c(
        "div",
        { staticClass: "container-fluid py-3" },
        [
          _c(
            "router-link",
            {
              staticClass: "w-100 navbar-label mr-auto text-dark",
              attrs: { to: "/user/" + _vm.data.idt_user }
            },
            [
              _c("mega-image", {
                staticClass: "rounded-full navbar-avatar mr-3",
                attrs: { small: "", src: _vm.data.avatar }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "content",
                  staticStyle: { width: "calc(100% - 78px)" }
                },
                [
                  _c("small", { staticClass: "strong text-overflow" }, [
                    _vm._v(_vm._s(_vm.data.name))
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted text-overflow" }, [
                    _vm.device
                      ? _c(
                          "span",
                          {
                            class: {
                              "text-success": _vm.device === "i-android"
                            },
                            attrs: {
                              title: _vm.data["sessions"][0]
                                ? _vm.$u2d(
                                    _vm.data["sessions"][0]["a_time"],
                                    "DD.MM.YYYY - LT"
                                  )
                                : ""
                            }
                          },
                          [_c("i", { class: _vm.device }), _vm._v(" - ")]
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n\n                        " +
                        _vm._s(_vm.data.username) +
                        "\n                    "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("nav", { staticClass: "nav flex-column font-small w-100" }, [
      _c("div", { staticClass: "nav-text text-muted" }, [
        _c("i", { staticClass: "i-mail" }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.data.email || "- - -"))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nav-text text-muted" }, [
        _c("i", { staticClass: "i-gift" }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.data["birthday_date"] || "- - -"))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nav-text text-muted" }, [
        _c("i", { staticClass: "i-user-plus" }),
        _vm._v(" "),
        _c("span", [
          _vm._v(_vm._s(_vm.$u2d(_vm.data["a_time"], "DD.MM - LT") || "- - -"))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }